import { Title } from '@solidjs/meta';
import { Button, TextLink, Tooltip, TooltipTrigger } from '@troon/ui';
import { StyleCard } from '../_style-card';

export default function TooltipsPage() {
	return (
		<>
			<Title>Tooltips | Interactive | Style Guide | Troon</Title>
			<h1 class="text-3xl font-semibold">Tooltips</h1>
			<div class="mb-8 grid grid-cols-12 gap-4">
				<StyleCard title="On Button">
					<TooltipTrigger>
						<Button>Hover me!</Button>
						<Tooltip>This is the content</Tooltip>
					</TooltipTrigger>
				</StyleCard>
				<StyleCard title="On Button">
					<TooltipTrigger>
						<div class="grow">
							<TextLink href="/" class="whitespace-nowrap">
								Hover me!
							</TextLink>
						</div>
						<Tooltip>This is the content</Tooltip>
					</TooltipTrigger>
				</StyleCard>{' '}
			</div>
		</>
	);
}
